// Import necessary modules and components from React and Material-UI
import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
  Button,
  Box,
  Modal,
  Fade,
  useMediaQuery,
  createTheme,
  /*ThemeProvider,*/
  styled,
  Backdrop
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { generateWords } from "./generateWords";
import { resetCompleted } from "../Redux/resetSlice";

// Modal styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  border: "2px solid darkgrey",
  minHeight: "200px",
  padding: "0 0 1.25em 0",
};

// Styled Backdrop to prevent clicks outside of the modal
const StyledBackdrop = styled(Backdrop)({
  pointerEvents: 'none', // Prevents clicking on the backdrop
});

// Functional component for the Memory game
export const Memory = () => {
  // Redux selector to access the levels state
  //const levels = useSelector((state) => state.levels);
  const dispatch = useDispatch();
  const resetFlag = useSelector((state) => state.reset.reset);
  const switchState = useSelector((state) => state.switch.switchState);
  const expandState = useSelector((state) => state.expand.switchState);
  const checkboxes = useSelector((state) => state.checkboxes);

  // State variables for managing game state and UI interactions
  const [refresh, setRefresh] = useState(0); // Initial state set to 0
  //const ImportedWords = useWords(); // Custom hook to fetch words
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ImportedArray = useMemo(() => generateWords(switchState, expandState, checkboxes.items), [refresh]);

  const [selectL1, setSelectL1] = useState(""); // Initial state set to an empty string
  const [selectL2, setSelectL2] = useState(""); // Initial state set to an empty string
  const [show, setShow] = useState([]); // Initial state set to an empty array
  const [correct, setCorrect] = useState([]); // Initial state set to an empty array
  const [clickable, setClickable] = useState(true); // Initial state set to true
  const [win, setWin] = useState(false); // Initial state set to false
  const [open, setOpen] = useState(false); // Initial state set to false
  const [message, setMessage] = useState(""); // Initial state set to an empty string
  const [closeModal, setCloseModal] = useState(false); // Initial state set to false

  // Define the custom theme with both width and height breakpoints
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 400,   // Extra small width breakpoint (400px)
        sm: 600,   // Small width breakpoint (600px)
        md: 900,   // Medium width breakpoint (900px)
        lg: 1100,  // Large width breakpoint (1100px)
        xl: 1920,  // Extra large width breakpoint (1920px)
        xxl: 2560, // Double extra large width breakpoint (2560px)
        xxxl: 3200,// Triple extra large width breakpoint (3200px)

        // Custom height breakpoints
        xsh: 300,  // Extra small height breakpoint (300px)
        smh: 400,  // Small height breakpoint (400px)
        mdh: 600,  // Medium height breakpoint (600px)
        lgh: 800,  // Large height breakpoint (800px)
        xlh: 1000, // Extra large height breakpoint (1000px)
        xxlh: 1500 // Double extra large height breakpoint (1500px)
      },
    },
  });

  // Media queries for responsive design
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));
  const isXxl = useMediaQuery(theme.breakpoints.down('xxl'));
  const isXxxl = useMediaQuery(theme.breakpoints.down('xxxl'));

  const isXsh = useMediaQuery(`(max-height:${theme.breakpoints.values.xsh}px)`);
  const isSmh = useMediaQuery(`(max-height:${theme.breakpoints.values.smh}px)`);
  const isMdh = useMediaQuery(`(max-height:${theme.breakpoints.values.mdh}px)`);
  const isLgh = useMediaQuery(`(max-height:${theme.breakpoints.values.lgh}px)`);
  const isXlh = useMediaQuery(`(max-height:${theme.breakpoints.values.xlh}px)`);
  const isXxlh = useMediaQuery(`(max-height:${theme.breakpoints.values.xxlh}px)`);

  // Function to determine font size based on screen size
  const getFontSize = () => {
    if (isXs && isXsh) return "0.5rem";
    if (isXs && isSmh) return "0.6rem";
    if (isXs && isMdh) return "0.65rem";
    if (isXs && isLgh) return "0.7rem";
    if (isXs && isXlh) return "0.75rem";
    if (isXs) return "0.7rem";
    if (isSm && isSmh) return "0.7rem";
    if (isSm && isMdh) return "0.8rem";
    if (isSm && isLgh) return "0.85rem";
    if (isSm && isXlh) return "0.9rem";
    if (isSm) return "0.8rem";
    if (isMd && isXsh) return "0.5rem";
    if (isMd && isSmh) return "0.6rem";
    if (isMd && isMdh) return "0.7rem";
    if (isMd && isLgh) return "1.2rem";
    if (isMd && isXlh) return "1.3rem";
    if (isMd && isXxlh) return "1.4rem";
    if (isMd) return "1.2rem";
    if (isLg && isSmh) return "0.6rem";
    if (isLg && isMdh) return "0.75rem";
    if (isLg && isLgh) return "1.3rem";
    if (isLg && isXlh) return "1.4rem";
    if (isLg && isXxlh) return "1.55rem";
    if (isLg) return "1.3rem";
    if (isXl && isMdh) return "1.1rem";
    if (isXl && isLgh) return "1.15rem";
    if (isXl && isXlh) return "1.3rem";
    if (isXl) return "1.2rem";
    if (isXxl) return "1.3rem";
    if (isXxxl) return "1.4rem";
    return "1.5rem"; // Default font size
  };
  // Function to handle correct answer
  const handleCorrect = () => {
    setMessage("Correct!");
    setOpen(true);
  };

  // Function to handle incorrect answer
  const handleIncorrect = () => {
    setMessage("Try again...");
    setOpen(true);
  };

  // Effect to handle card selection logic
  useEffect(() => {
    //console.log(ImportedArray);
    if (selectL1 && selectL2) {
      setClickable(false);
      const selectQ = ImportedArray.find((word) => word.Q === selectL1);

      if (selectQ && selectL2 === selectQ.A) {
        setCorrect((prevCorrect) => [...new Set([...prevCorrect, selectL1, selectL2])]);
        handleCorrect();
      } else {
        handleIncorrect();
      }

      setTimeout(() => {
        if (correct.length === ImportedArray.length) {
          setWin(true);
          setCloseModal(true);
        }
      }, 800);

      setTimeout(() => {
        setSelectL1("");
        setSelectL2("");
        setShow([]);
        setClickable(true);
      }, 800);
    }
  }, [selectL1, selectL2, correct, ImportedArray]);

  // Function to handle card selection
  const selectCard = (wordId) => {
    if (!selectL1) setSelectL1(wordId);
    else setSelectL2(wordId);
    setShow((prevShow) => [...prevShow, wordId]);
  };

  // Function to handle Snackbar close event
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  // Function to reset the game
  const reset = () => {
    setRefresh((prev) => prev + 1);
    setWin(false);
    setCorrect([]);
    console.log("Reset completed");
  };

  useEffect(() => {
    if (resetFlag) {
      reset(); // Call your reset logic
      dispatch(resetCompleted()); // Reset the flag in the Redux store
    }
  }, [resetFlag, dispatch]);

  // Function to handle modal close event
  const handleCloseModal = () => setCloseModal(false);

  // Function to handle play again event
  const handleCloseModalWithReplay = () => {
    reset();
    setCloseModal(false);
  };

  return (
    <div className="memory-game-wrapper" style={{ marginTop: "0em", paddingTop: "5em", overflow: "hidden", height: "100vh" }}>
      <div className="CardsContainer" style={{ pointerEvents: !clickable && "none" }}>
        <Grid container spacing={0}>
          {ImportedArray?.map((word) => (
            <Grid item xs={4} sm={3} md={3} ld={3} key={word.Q} style={{ minWidth: "5em" }}>
              <div
                className={show.includes(word.Q) || correct.includes(word.Q) ? "card flip" : "card"}
                id={word.Q}
                style={{
                  height: "100%", // Set card height
                  width: "100%", // Set card width
                }}
                onClick={() => !correct.includes(word.Q) && !show.includes(word.Q) && selectCard(word.Q)}
              >
                <div className="card-face back-face" />
                <div
                  className="card-face front-face"
                  style={{
                    borderRadius: "0.3125em",
                    border: "0.3125em solid rgb(185, 204, 218)",
                    boxSizing: "border-box",
                    backgroundColor: correct.includes(word.Q)
                      ? "white"
                      : word.front === "L1"
                      ? "rgba(208, 231, 247, 0.76)"
                      : "rgb(185, 204, 218)",
                  }}
                >
                  <center>
                    <h5
                      style={{
                        color: "#003D7C",
                        fontWeight: "500", // Set card font weight
                        fontSize: getFontSize(), // Set card font scaling
                        userSelect: "none",
                      }}
                    >
                      {word.Q}
                    </h5>
                  </center>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

 {/*     {win && (
        <center>
          <br />
          <Button
            size="large"
            variant="contained"
            sx={{
              paddingBottom: "0.625em",
              textTransform: "none",
              backgroundColor: "#b9ccda",
              "&:hover": {
                backgroundColor: "#8699a7",
              },
            }}
            onClick={reset}
          >
            Play again
          </Button>
        </center>
      )} */}

      <Snackbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "2em",
        }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: message === "Correct!" ? "green" : "grey",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 1em",
            whiteSpace: "nowrap",
          }}
          message={
            <Typography variant="body2" style={{ textAlign: "center" }}>
              {message}
            </Typography>
          }
        />
      </Snackbar>

      <Modal
        open={win && closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}
        closeAfterTransition 
        BackdropComponent={StyledBackdrop}
        BackdropProps={{ timeout: 5000 }}
      >
        <Fade in={closeModal && win}>
          <Box sx={style}>
            <div
              style={{
                zIndex: 0,
                padding: "0",
                margin: "0",
                marginLeft: "-0.0125em",
                marginTop: "-0.03125em",
                paddingTop: "0.1875em",
                width: "100%",
                height: "2.8125em",
                borderRadius: "6px 6px 0 0",
                backgroundColor: "#003D7C",
              }}
            >
              <div
                style={{
                  float: "right",
                  display: "block",
                  color: "#EF7C00",
                  paddingTop: "0.3125em",
                  paddingRight: "0.625em",
                }}
              >
                <CloseIcon onClick={handleCloseModal} />
              </div>
              <h2
                style={{
                  display: "block",
                  padding: "0",
                  margin: "0",
                  textAlign: "left",
                  paddingLeft: "0.625em",
                  paddingBottom: "0",
                  paddingTop: "0.35em",
                  fontSize: "1.125em",
                }}
              >
                Congratulations!
              </h2>
            </div>
            <center>
              <div style={{ paddingTop: "1.25em", paddingBottom: "1.875em" }}>
                <h3
                  style={{
                    display: "flex",
                    padding: "0",
                    margin: "0",
                    textAlign: "center",
                    paddingLeft: "0.625em",
                    paddingBottom: "0",
                    paddingTop: "0.35em",
                    fontSize: "1.125em",
                  }}
                >
                  You won! Would you like to play again?
                </h3>
              </div>
              <Button
                size="large"
                variant="contained"
                sx={{
                  paddingBottom: "0.625em",
                  textTransform: "none",
                  backgroundColor: "#003D7C",
                  "&:hover": {
                    backgroundColor: "#EF7C00",
                  },
                }}
                onClick={handleCloseModalWithReplay}
              >
                Play again
              </Button> 
            </center>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
