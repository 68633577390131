//import { useSelector } from "react-redux";
import { WORDS } from "./WORDS";

/**
 * Function to generate an array of words based on given parameters.
 * Ensures the array contains 6 unique pairs of words, filtered and shuffled.
 * @param {boolean} switchState - Current switch state for filtering words.
 * @param {boolean} expandState - Current expand state for filtering words.
 * @param {Array} checkboxes - Array of checkbox items, each with a 'checked' property and 'label'.
 * @returns {Array} shuffledWords - An array of word objects with both L1 and L2 representations, shuffled.
 */
export const generateWords = (switchState, expandState, checkboxes) => {
  // Map checkboxes to levels and append "Undefined"
  const levels = checkboxes
    .filter((item) => item.checked)
    .map((item) => item.label)
    .concat("Undefined");

  /**
   * Function to filter words based on the switch state.
   * @param {Object} word - The word object to filter.
   * @returns {Boolean} - Whether the word passes the filter criteria.
   */
  const filterWords = (word) => {
    // If switchState is false, only include words with SG value "Yes"
    if (switchState === false) {
      return word.SG === "Yes";
    }
    // If switchState is true, include words with SG value "Yes" or "No"
    return word.SG === "Yes" || word.SG === "No";
  };

  const shrinkWords = (word) => {
    // If expandState is false, filter all "Undefined" levels
    if (expandState === false) {
      console.log("Expanded drug list not selected, dropping Undefined");
      return word.level !== "Undefined";
    }
    const allSelected = checkboxes.every(item => item.checked);
    // If not all levels are selected, filter all "Undefined" levels
    if (!allSelected) {
      console.log("Expanded drug list selected, Not all topics selected, dropping Undefined");
      return word.level !== "Undefined";
    }
    // Else if expandState is true and all levels are selected
    console.log("Conditions met for expanded drug list, including Undefined")
    return word.level === "Undefined" || word.level !== "Undefined";
  }

  /**
   * Function to generate an array of 6 unique words that match the levels and filter criteria.
   * Ensures no duplicates based on the l1 property.
   * @returns {Array} - An array of 6 unique word objects.
   */
  const generateUniqueWords = () => {
    const uniqueWords = []; // Array to store the unique words
    const uniqueWordL1Set = new Set(); // Set to track the unique l1 values
    const uniqueWordL2Set = new Set(); // Set to track the unique l2 values
    console.log("Levels selected: ", levels);
    console.log("WHO drugs selected: ", switchState);
    console.log("Expanded list selected: ", expandState);
    // Loop until we have 6 unique words
    while (uniqueWords.length < 6) {
      const randomIndex = Math.floor(Math.random() * WORDS.length); // Generate a random index
      const randomWord = WORDS[randomIndex]; // Select a word at the random index

      

      // Ensure the word is unique, matches a level, and l1 is different from l2, no duplicates
      if (
        levels.includes(randomWord.level) && // Check if the word's level is in the selected levels
        filterWords(randomWord) && // Apply the filter based on switchState
        shrinkWords(randomWord) &&
        !uniqueWordL1Set.has(randomWord.l1) && // Check no l1 to l1 clash
        !uniqueWordL2Set.has(randomWord.l2) && // Check no l2 to l2 clash
        !uniqueWordL1Set.has(randomWord.l2) && // Check no l2 to l1 clash
        !uniqueWordL2Set.has(randomWord.l1) && // Check no l1 to l2 clash
        randomWord.l1 !== randomWord.l2 // Ensure l1 is different from l2
      ) {
        uniqueWords.push(randomWord); // Add the word to the array if it meets all criteria
        uniqueWordL1Set.add(randomWord.l1); // Add the l1 value to the set to track uniqueness
        uniqueWordL2Set.add(randomWord.l2); // Add the l1 value to the set to track uniqueness
      }
    }
    return uniqueWords; // Return the array of unique words
  };

  // Generate an array of 6 unique words
  const uniqueWordsArray = generateUniqueWords();

  // Initialize an array to store the shuffled words with both L1 and L2 representations
  const shuffledWords = [];

  // Map over the uniqueWordsArray to create objects for both L1 and L2 sides
  uniqueWordsArray.forEach((word) => {
    const wordL1 = { Q: word.l1, A: word.l2, front: "L1" }; // Object for L1 side
    const wordL2 = { Q: word.l2, A: word.l1, front: "L2" }; // Object for L2 side
    shuffledWords.push(wordL1, wordL2); // Add both objects to the shuffledWords array
  });

  /**
   * Function to shuffle an array using the Fisher-Yates algorithm.
   * @param {Array} array - The array to shuffle.
   */
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Generate a random index
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements at indices i and j
    }
  };

  // Shuffle the array of words
  shuffleArray(shuffledWords);

  // Return the shuffled array of words
  return shuffledWords;
};
