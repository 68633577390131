export const WORDS = [
    {l1: "\u2011ac e.g., diclofenac", l2: "anti-inflammatory agents, ibufenac derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011ac e.g., diclofenac", l2: "anti-inflammatory agents, ibufenac derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011actide", l2: "synthetic polypeptides with a corticotropin-like action", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011actide", l2: "synthetic polypeptides with a corticotropin-like action", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011adenant", l2: "adenosine receptors antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011adol  or \u2011adol\u2011 e.g., tramadol", l2: "analgesics", level: "Foundational", SG: "Yes"},
    {l1: "\u2011adol or \u2011adol\u2011 e.g., tramadol", l2: "analgesics", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011adom", l2: "analgesics, tifluadom derivatives", level: "Foundational", SG: "No"},
    {l1: "\u2011afenone e.g., propafenone", l2: "anti\u00ADarrhythmics, propafenone derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011afil e.g., sildenafil", l2: "inhibitors of phospho\u00ADdiesterase PDE5 with vasodilator action", level: "Reproductive / Urogenital / Urogenital", SG: "Yes"},
    {l1: "\u2011aj\u2011", l2: "anti\u00ADarrhythmics, ajmaline derivatives", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011aldrate", l2: "antacids, aluminium salts", level: "Gastrointestinal", SG: "No"},
    {l1: "\u2011ampanel e.g., perampanel", l2: "antagonists of ionotropic non-NMDA glutamate receptors", level: "Neuroscience", SG: "Yes"},
    {l1: "andr (former stem no longer acknowledged by INN) e.g., nandrolone", l2: "steroids, androgens", level: "Musculoskeletal", SG: "Yes"},
    {l1: "andr (former stem no longer acknowledged by INN) e.g., nandrolone", l2: "steroids, androgens", level: "Cancer", SG: "Yes"},
    {l1: "andr (former stem no longer acknowledged by INN) e.g., nandrolone", l2: "steroids, androgens", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011anib e.g., pazopanib", l2: "angiogenesis inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011anide e.g., bumetanide", l2: "diuretics, piretanide derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011anide e.g., bumetanide", l2: "diuretics, piretanide derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011anserin", l2: "serotonin receptor antagonists (mostly 5-HT2)", level: "Neuroscience", SG: "No"},
    {l1: "\u2011antel e.g., praziquantel", l2: "anthelminthics (undefined group)", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011antrone e.g., mitoxantrone", l2: "anti\u00ADneoplastics; anthraquinone derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011apt\u2011 (not \u2011vaptan or \u2011capto\u2011)", l2: "aptamers, classical & mirror ones", level: "Undefined", SG: "No"},
    {l1: "\u2011(ar)abine (not \u2011citabine) e.g., clofarabine", l2: "arabino\u00ADfuranosyl derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011arit", l2: "antiarthritic substances, acting like clobuzarit & lobenzarit", level: "Musculoskeletal", SG: "No"},
    {l1: "\u2011arol (former stem no longer acknowledged by INN)", l2: "anti\u00ADcoagulants, dicoumarol derivatives", level: "Blood", SG: "No"},
    {l1: "\u2011arone e.g., amiodarone", l2: "anti\u00ADarrhythmics", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011arotene e.g., bexarotene", l2: "arotinoid derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011arotene e.g., trifarotene ", l2: "arotinoid derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "arte\u2011", l2: "antimalarial agents, artemisinin related compounds", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011diplase", l2: "two plasminogen activators combined with another enzyme", level: "Blood", SG: "No"},
    {l1: "\u2011dimase", l2: "superoxide dismutase activity", level: "Undefined", SG: "No"},
    {l1: "\u2011lipase", l2: "lipases", level: "Undefined", SG: "No"},
    {l1: "\u2011teplase e.g., alteplase", l2: "tissue-type plasminogen activators", level: "Blood", SG: "Yes"},
    {l1: "\u2011teplase e.g., alteplase", l2: "tissue-type plasminogen activators", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011teplase e.g., alteplase", l2: "tissue-type plasminogen activators", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011uplase", l2: "urokinase (urinary)-type plasminogen activators", level: "Blood", SG: "No"},
    {l1: "\u2011ast → \u2011milast e.g., montelukast", l2: "anti-allergic or anti-inflammatory → leukotriene receptor antagonists", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011ast → \u2011lukast e.g., apremilast", l2: "anti-allergic or anti-inflammatory → phospho\u00ADdiesterase IV (PDE IV) inhibitors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ast → \u2011lukast e.g., apremilast", l2: "anti-allergic or anti-inflammatory → phospho\u00ADdiesterase IV (PDE IV) inhibitors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ast → \u2011tegrast", l2: "integrin antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011ast → \u2011trodast", l2: "thromboxane A2 receptor antagonists, antiasthmatics", level: "Respiratory", SG: "No"},
    {l1: "\u2011ast → \u2011zolast", l2: "leukotriene biosynthesis inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011astine (not vinblastine) e.g., bilastine", l2: "anti\u00ADhistaminics", level: "Foundational", SG: "Yes"},
    {l1: "\u2011azenil e.g., flumazenil", l2: "benzo\u00ADdiazepine receptor antagonists / agonists (benzo\u00ADdiazepine derivatives)", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011carnil", l2: "benzodiazepine receptor antagonists / agonists (carboline derivatives)", level: "Neuroscience", SG: "No"},
    {l1: "\u2011quinil", l2: "benzodiazepine receptor agonists, also partial / inverse (quinoline derivatives)", level: "Neuroscience", SG: "No"},
    {l1: "\u2011azepam e.g., clonazepam", l2: "diazepam derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011azolam (not preferred stem) e.g., midazolam", l2: "related to diazepam derivatives [preferred stem: -azepam (x)]", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011azolam (not preferred stem) e.g., midazolam", l2: "related to diazepam derivatives [preferred stem: -azepam (x)]", level: "Foundational", SG: "Yes"},
    {l1: "\u2011azepide", l2: "cholecysto\u00ADkinin receptor antagonists, benzodiazepine derivatives", level: "Neuroscience", SG: "No"},
    {l1: "\u2011azocine (not streptozocin)", l2: "narcotic antagonists / agonists related to 6,7-benzo\u00ADmorphan", level: "Neuroscience", SG: "No"},
    {l1: "\u2011azoline (not cefazolin) e.g., oxymetazoline", l2: "anti\u00ADhistaminics or local vaso\u00ADconstrictors, antazoline derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011azoline (not cefazolin) e.g., oxymetazoline", l2: "anti\u00ADhistaminics or local vaso\u00ADconstrictors, antazoline derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011azosin e.g., doxazosin", l2: "anti\u00ADhypertensive substances, prazosin derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011azosin e.g., doxazosin", l2: "anti\u00ADhypertensive substances, prazosin derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011uzosin (not preferred stem) e.g., afluzosin", l2: "urogenital selective related to anti\u00ADhypertensive substances, prazosin derivatives", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011ulosin (not preferred stem) e.g., tamsulosin", l2: "urogenital selective related to anti\u00ADhypertensive substances, prazosin derivatives", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011bactam e.g., tazobactam", l2: "β-lactamase inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011bactam → \u2011borbactam", l2: "β-lactamase inhibitors → boronic acid derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011bamate", l2: "tran\u00ADquill\u00ADizers, propanediol & pentanediol derivatives", level: "Neuroscience", SG: "No"},
    {l1: "barb (former stem no longer acknowledged by INN) e.g., phenobarbitone", l2: "hypnotics, barbituric acid derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011begron e.g., mirabegron", l2: "β3-adrenoceptor agonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011begron e.g., mirabegron", l2: "β3-adrenoceptor agonists", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011bendazole e.g., albendazole", l2: "anthelm\u00ADinth\u00ADics, tiabendazole derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011bep", l2: "engineered or synthetic scaffold proteins, non-immunoglobulin variable domain derived", level: "Undefined", SG: "No"},
    {l1: "\u2011bersat", l2: "anti\u00ADconvulsants, benzoylamino-benzpyran derivatives", level: "Neuroscience", SG: "No"},
    {l1: "bol e.g., tibolone", l2: "anabolic steroids", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011bresib", l2: "inhibitors of the BET family of BRD proteins, anti\u00ADneoplastics", level: "Cancer", SG: "No"},
    {l1: "\u2011bradine e.g., ivabradine", l2: "bradycardic agents", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011bufen", l2: "non-steroidal anti-inflammatory agents, arylbutanoic acid derivatives", level: "Foundational", SG: "No"},
    {l1: "\u2011bulin (not thyroglobulin) e.g., eribulin", l2: "anti\u00ADneoplastics; mitotic inhibitors, tubulin binders", level: "Cancer", SG: "Yes"},
    {l1: "\u2011buzone", l2: "anti-inflammatory analgesics, phenylbutazone derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011azone (not preferred stem) [not pioglitazone] e.g., phenazone", l2: "anti-inflammatory analgesics, phenylbutazone derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011caftor", l2: "CFTR protein modulators, correctors & amplifiers", level: "Undefined", SG: "No"},
    {l1: "\u2011caine e.g., lidocaine", l2: "local anaesthetics", level: "Foundational", SG: "Yes"},
    {l1: "\u2011caine e.g., lidocaine", l2: "local anaesthetics", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011cain\u2011 e.g., procainamide", l2: "Class I anti\u00ADarrhythmics, procainamide & lidocaine derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011calcet/\u2011calcet\u2011 e.g., cinacalcet ", l2: "calcium-sensing receptors (CaSR) agonists", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011calcet/\u2011calcet\u2011 e.g., cinacalcet ", l2: "calcium-sensing receptors (CaSR) agonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "calci (not calcitonin) e.g., alfacalcidol", l2: "Vitamin D analogues / derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "calci (not calcitonin) e.g., alfacalcidol", l2: "Vitamin D analogues / derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011capone e.g., entacapone", l2: "catechol-O-methyl\u00ADtransferase (COMT) inhibitors", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011carbef", l2: "antibiotics, carbacephem derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011catib", l2: "cathepsin inhibitors", level: "Undefined", SG: "No"},
    {l1: "cef\u2011 e.g., cefalexin", l2: "antibiotics, cefalo\u00ADsporanic acid derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011oxef", l2: "antibiotics, oxa\u00ADcefalo\u00ADsporanic acid derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011cel", l2: "substances for cell therapies", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011bacept", l2: "B-cell activating factor receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011bercept e.g., aflibercept", l2: "receptor molecules or membrane ligands → VEGF receptors", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011cept → \u2011bercept e.g., aflibercept", l2: "receptor molecules or membrane ligands → VEGF receptors", level: "Foundational", SG: "Yes"},
    {l1: "\u2011cept → \u2011cocept", l2: "receptor molecules or membrane ligands → complement receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011farcept", l2: "receptor molecules or membrane ligands →  subgroup of interferon receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011lefacept", l2: "receptor molecules or membrane ligands →LFA-3 receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011nacept", l2: "receptor molecules or membrane ligands → interleukin-1 receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011nercept e.g., etanercept", l2: "receptor molecules or membrane ligands → Tumour Necrosis Factor (TNF) receptors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011cept → \u2011tacept", l2: "receptor molecules or membrane ligands → CTLA-4 receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cept → \u2011tercept e.g., luspatercept", l2: "receptor molecules or membrane ligands → transforming growth factor receptors", level: "Blood", SG: "Yes"},
    {l1: "\u2011cept → \u2011vircept", l2: "receptor molecules or membrane ligands → antiviral receptors", level: "Undefined", SG: "No"},
    {l1: "\u2011cerfont", l2: "corticotropin-releasing factor (CRF) receptor antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011cetrapib", l2: "cholesteryl ester transfer protein (CETP) inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011cianine", l2: "indocyanine fluorescence dye group", level: "Undefined", SG: "No"},
    {l1: "\u2011cic (not bisorcic or cromoglicic)", l2: "hepato\u00ADprotective substances with a carboxylic acid group", level: "Undefined", SG: "No"},
    {l1: "\u2011ciclib e.g., palbociclib", l2: "cyclin dependant kinase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011cidin e.g., fucidin", l2: "naturally occurring antibiotics (undefined group)", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011ciguat e.g., riociguat", l2: "guanylate cyclase activators & stimulators", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011cillin e.g., amoxicillin", l2: "antibiotics, 6-amino\u00ADpenicill\u00ADanic acid derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011citabine e.g., gemcitabine", l2: "nucleosides antiviral or antineoplastic agents, cytarabine or azacitidine derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011citabine e.g., emtricitabine", l2: "nucleosides antiviral or antineoplastic agents, cytarabine or azacitidine derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011clidine/\u2011clidinium e.g., umeclidinium", l2: "muscarinic receptors agonists / antagonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011clidine/\u2011clidinium e.g., umeclidinium", l2: "muscarinic receptors agonists / antagonists", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011clidine/\u2011clidinium e.g., clidinium", l2: "muscarinic receptors agonists / antagonists", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011clone e.g., zopiclone", l2: "hypnotic tranquillizers", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011(clo)sporin e.g., ciclosporin", l2: "ciclosporin derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011cog → (\u2011)eptacog e.g., eptacog alfa", l2: "blood coagulation factors → blood coagulation VII", level: "Blood", SG: "Yes"},
    {l1: "\u2011cog → (\u2011)octocog e.g., rurioctocog alfa pegol", l2: "Vitamin D analogues / derivatives", level: "Blood", SG: "Yes"},
    {l1: "\u2011cog → (\u2011)nonacog e.g., albu\u00ADtrepe\u00ADnonacog alfa", l2: "blood coagulation factors → blood coagulation IX", level: "Blood", SG: "Yes"},
    {l1: "\u2011cog → (\u2011)tridecacog", l2: "blood coagulation factors → blood coagulation XIII", level: "Blood", SG: "No"},
    {l1: "\u2011cogin", l2: "blood coagulation cascade inhibitors", level: "Blood", SG: "No"},
    {l1: "\u2011conazole e.g., fluconazole", l2: "systemic antifungal agents, miconazole derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011corilant", l2: "glucocorticoid receptor antagonists (non-steroidal)", level: "Undefined", SG: "No"},
    {l1: "cort e.g., hydrocortisone", l2: "cortico\u00ADsteroids, except prednisolone derivatives", level: "Foundational", SG: "Yes"},
    {l1: "cort e.g., hydrocortisone", l2: "cortico\u00ADsteroids, except prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "cort e.g., hydrocortisone", l2: "cortico\u00ADsteroids, except prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011corat", l2: "glucocorticoid receptor agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011copan", l2: "complement receptor antagonists /  complement inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011coxib e.g., celecoxib", l2: "selective cyclo-oxygenase inhibitors", level: "Foundational", SG: "Yes"},
    {l1: "\u2011coxib e.g., celecoxib", l2: "selective cyclo-oxygenase inhibitors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011crinat", l2: "diuretics, etacrynic acid derivatives", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011crine (former stem no longer acknowledged by INN) e.g., aminacrine", l2: "acridine derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011crine (former stem no longer acknowledged by INN) e.g., aminacrine", l2: "acridine derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011cromil", l2: "antiallergics, cromoglicic acid derivatives", level: "Respiratory", SG: "No"},
    {l1: "\u2011cycline (former stem no longer acknowledged by INN) e.g., doxycycline", l2: "antibiotics, protein-synthesis inhibitors, tetracycline derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011dan", l2: "cardiac stimulants, pimobendan derivatives", level: "Cardiovascular", SG: "No"},
    {l1: "(\u2011)dapsone e.g., dapsone", l2: "anti\u00ADmyco\u00ADbacterials, diamino\u00ADdi\u00ADphenyl\u00ADsulfone derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011degib e.g., vismodegib", l2: "SMO receptor antagonists", level: "Cancer", SG: "Yes"},
    {l1: "\u2011denoson", l2: "adenosine A receptor agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011dil e.g., ripasudil", l2: "vasodilators", level: "Foundational", SG: "Yes"},
    {l1: "\u2011dil e.g., alprostadil ", l2: "vasodilators", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011dil → \u2011dilol e.g., carvedilol", l2: "vasodilators", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011dil → \u2011fradil", l2: "vasodilators → calcium channel blockers acting as vasodilators", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011dil → \u2011sudil e.g., netarsudil", l2: "vasodilators → Rho protein kinase inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011dipine e.g., amlodipine", l2: "calcium channel blockers, nifedipine derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011domide e.g., lenalidomide", l2: "anti\u00ADneoplastics, thalidomide derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011dopa e.g., levodopa", l2: "dopamine agonists & derivatives, used as anti\u00ADparkinsonism / prolactin inhibitors", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011dopa e.g., methyldopa", l2: "dopamine agonists & derivatives, used as anti\u00ADparkinsonism / prolactin inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011opamine e.g., dopamine", l2: "DAergic agents & derivatives as cardiac stimulants / anti\u00ADhypertensives / diuretics", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011dotin e.g., brentuximab vedotin", l2: "synthetic derivatives of dolastatin series", level: "Cancer", SG: "Yes"},
    {l1: "\u2011dralazine e.g., hydrazaline", l2: "anti\u00ADhypertensives, hydrazine\u00ADphthala\u00ADzine derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011drine e.g., pseudo\u00ADephedrine", l2: "sympatho\u00ADmimetics", level: "Foundational", SG: "Yes"},
    {l1: "\u2011drine e.g., pseudo\u00ADephedrine", l2: "sympatho\u00ADmimetics", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011frine", l2: "sympatho\u00ADmimetic, phenethyl derivatives", level: "Foundational", SG: "No"},
    {l1: "\u2011dronic acid e.g., alendronic acid", l2: "calcium metabolism regulator, pharmaceutical aid", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ectin e.g., ivermectin", l2: "anti\u00ADparasitics, ivermectin derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011emcinal", l2: "erythromycin derivatives lacking antibiotic activity, motilin agonists", level: "Gastrointestinal", SG: "No"},
    {l1: "\u2011entan e.g., ambrisentan", l2: "endothelin receptor antagonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "erg e.g., cabergoline", l2: "ergot alkaloid derivatives", level: "Endocrine", SG: "Yes"},
    {l1: "erg e.g., ergometrine", l2: "ergot alkaloid derivatives", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "erg e.g., ergotamine", l2: "ergot alkaloid derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011eridine", l2: "analgesics, pethidine derivatives &  synthetic small molecule μ-opioid agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011bermin", l2: "growth factors → vascular endothelial growth factors", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011fermin", l2: "growth factors → fibroblast growth factors", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011dermin", l2: "growth factors → epidermal growth factors", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011filermin", l2: "growth factors → leukemia-inhibiting factor", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011nermin", l2: "growth factors → tumour necrosis factor", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011plermin", l2: "growth factors → platelet-derived growth factor", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011sermin", l2: "growth factors → insulin-like growth factors", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011termin", l2: "growth factors → transforming growth factor", level: "Undefined", SG: "No"},
    {l1: "\u2011ermin → \u2011otermin", l2: "growth factors → bone morphogenic proteins", level: "Undefined", SG: "No"},
    {l1: "\u2011espib", l2: "heat shock protein (HSP) 90 inhibitors (other than –mycin)", level: "Undefined", SG: "No"},
    {l1: "est or \u2011gestr\u2011 e.g., etonogestrel", l2: "estrogens", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "est or \u2011gestr\u2011 e.g., etonogestrel", l2: "estrogens", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011estrant e.g., fulvestrant", l2: "estrogen antagonists, including estrogen receptor down-regulators", level: "Cancer", SG: "Yes"},
    {l1: "\u2011exine e.g., bromhexine", l2: "mucolytic, bromhexine derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011fenacin e.g., revefenacin ", l2: "muscarinic receptor antagonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011fenacin e.g., revefenacin ", l2: "muscarinic receptor antagonists", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011fenacin e.g., solifenacin", l2: "muscarinic receptor antagonists", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011fenamic acid or \u2011fenamate e.g., mefenamic acid", l2: "anti-inflammatory, anthranilic acid (fenamic acid) derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011fenamic acid or \u2011fenamate e.g., mefenamic acid", l2: "anti-inflammatory, anthranilic acid (fenamic acid) derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011fenicol", l2: "antibacterial antibiotics, chlor\u00ADamphen\u00ADicol analogues", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011fenin", l2: "diagnostic aids; (phenyl\u00ADcarbamoyl)\u00ADmethyl imino\u00ADdiacetic acid derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011fenine or \u2011phenine", l2: "analgesics, glafenine derivatives (subgroup of fenamic acid group)", level: "Foundational", SG: "No"},
    {l1: "\u2011fensine", l2: "nor\u00ADepineph\u00ADrine, serotonin, dopamine reuptake inhibitors", level: "Neuroscience", SG: "No"},
    {l1: "\u2011fentanil e.g., alfentanil", l2: "opioid receptor agonists, analgesics, fentanyl derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011fentanil e.g., alfentanil", l2: "opioid receptor agonists, analgesics, fentanyl derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011fentrine", l2: "inhibitors of phospho\u00ADdiester\u00ADases", level: "Undefined", SG: "No"},
    {l1: "\u2011fexor", l2: "farnesoid X receptor agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011fiban e.g., tirofiban", l2: "fibrinogen receptor antagonists (glycoprotein IIb/IIIa receptor antagonists)", level: "Blood", SG: "Yes"},
    {l1: "\u2011fibrate e.g., fenofibrate", l2: "clofibrate derivatives, PPAR-α agonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011flapon", l2: "5-lipoxygenase-activating protein (FLAP) inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011flurane e.g., sevoflurane", l2: "halogenated compounds used as general inhalation anaesthetics", level: "Foundational", SG: "Yes"},
    {l1: "\u2011flurane e.g., sevoflurane", l2: "halogenated compounds used as general inhalation anaesthetics", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011formin (former stem no longer acknowledged by INN) e.g., metformin", l2: "anti\u00ADhyper\u00ADglycaemics, phenformin derivatives", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011fos or \u2011vos", l2: "insecticides, anthelm\u00ADinth\u00ADics, pesticides etc., phosphorous derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011fosfamide or \u2011phosphamide e.g., cyclo\u00ADphosph\u00ADamide", l2: "alkylating agents of the cyclo\u00ADphosph\u00ADamide group", level: "Foundational", SG: "Yes"},
    {l1: "\u2011fosfamide or \u2011phosphamide e.g., cyclo\u00ADphosph\u00ADamide", l2: "alkylating agents of the cyclo\u00ADphosph\u00ADamide group", level: "Cancer", SG: "Yes"},
    {l1: "\u2011fungin e.g., micafungin", l2: "antifungal antibiotics", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011fusp", l2: "fusion proteins", level: "Undefined", SG: "No"},
    {l1: "\u2011fylline or \u2011phylline e.g., pentoxifylline", l2: "N-methylated xanthine derivatives", level: "Blood", SG: "Yes"},
    {l1: "\u2011fylline or \u2011phylline e.g., theophylline", l2: "N-methylated xanthine derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "gab e.g., pregabalin", l2: "gabamimetic agents", level: "Neuroscience", SG: "Yes"},
    {l1: "gado\u2011 e.g., gadobutrol", l2: "diagnostic agents, gadolinium derivatives", level: "Undefined", SG: "Yes"},
    {l1: "\u2011ganan", l2: "anti\u00ADmicrobials, permeability increasing peptides", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011gatran e.g., dabigatran etexilate", l2: "thrombin inhibitors, antithrombotic agents", level: "Blood", SG: "Yes"},
    {l1: "\u2011gepant e.g., rimegepant", l2: "calcitonin gene-related peptide receptor antagonists", level: "Neuroscience", SG: "Yes"},
    {l1: "gest e.g., dienogest", l2: "steroids, progestogens", level: "Endocrine", SG: "Yes"},
    {l1: "gest e.g., dienogest", l2: "steroids, progestogens", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011giline e.g., selegiline", l2: "MAO-inhibitors type B", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011gillin", l2: "antibiotics produced by Aspergillus strains", level: "Antimicrobial", SG: "No"},
    {l1: "gli (previously gly) [not cromoglicic acid] e.g., glibenclamide", l2: "anti\u00ADhyper\u00ADglycaemics", level: "Endocrine", SG: "Yes"},
    {l1: "gli → \u2011gliflozin e.g., empagliflozin", l2: "anti\u00ADhyper\u00ADglycaemics → Na glucose co-transporter inhibitors, phlorizin derivatives", level: "Endocrine", SG: "Yes"},
    {l1: "gli → \u2011gliptin e.g., sitagliptin", l2: "anti\u00ADhyper\u00ADglycaemics → dipeptidyl amino\u00ADpeptid\u00ADase–IV inhibitors", level: "Endocrine", SG: "Yes"},
    {l1: "gli → \u2011glitazar", l2: "anti\u00ADhyper\u00ADglycaemics → dual PPAR- α & γ agonists", level: "Endocrine", SG: "No"},
    {l1: "gli → \u2011glitazone e.g., pioglitazone", l2: "anti\u00ADhyper\u00ADglycaemics → PPAR-γ agonists, thiazo\u00ADlidine\u00ADdione derivatives", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011glumide", l2: "cholecysto\u00ADkinin antagonists, antiulcer, anxiolytic agents", level: "Gastrointestinal", SG: "No"},
    {l1: "\u2011glumide", l2: "cholecysto\u00ADkinin antagonists, antiulcer, anxiolytic agents", level: "Neuroscience", SG: "No"},
    {l1: "\u2011glurant", l2: "metabotropic glutamate receptor antagonists / negative allosteric modulators", level: "Undefined", SG: "No"},
    {l1: "\u2011golide", l2: "dopamine receptor agonists, ergoline derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011golix", l2: "gonadotropin releasing hormone (GnRH) antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011grel(\u2011) e.g., clopidogrel", l2: "platelet aggregation inhibitors", level: "Blood", SG: "Yes"},
    {l1: "guan\u2011", l2: "anti\u00ADhypertensives, guanidine derivatives", level: "Blood", SG: "No"},
    {l1: "\u2011icam e.g., meloxicam", l2: "anti-inflammatory, isoxicam derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011icam e.g., meloxicam", l2: "anti-inflammatory, isoxicam derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ifene e.g., raloxifene", l2: "antiestrogens or estrogen receptor modulators, clomifene & tamoxifen derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ilide", l2: "class III anti\u00ADarrhythmics", level: "Cardiovascular", SG: "No"},
    {l1: "imex", l2: "immuno\u00ADstimulants", level: "Undefined", SG: "No"},
    {l1: "\u2011imibe e.g., ezetimibe", l2: "anti\u00ADhyper\u00ADlipid\u00ADaemics, acyl CoA: cholesterol acyl\u00ADtransfer\u00ADase (ACAT) inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011imod e.g., fingolimod", l2: "immuno\u00ADmodulators, both stimulant / suppressive & stimulant", level: "Foundational", SG: "Yes"},
    {l1: "\u2011imod → \u2011mapimod", l2: "immuno\u00ADmodulators, both stimulant / suppressive & stimulant → MAP kinase inhibitors", level: "Foundational", SG: "No"},
    {l1: "\u2011imod → \u2011tolimod", l2: "immuno\u00ADmodulators, both stimulant / suppressive & stimulant → TLR agonists", level: "Foundational", SG: "No"},
    {l1: "\u2011imus", l2: "immuno\u00ADsuppressants", level: "Foundational", SG: "No"},
    {l1: "\u2011imus → \u2011rolimus e.g., sirolimus", l2: "immuno\u00ADsuppressants → immuno\u00ADsuppressants, rapamycin derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011ine (former stem no longer acknowledged by INN) e.g., atropine", l2: "alkaloids & organic bases", level: "Undefined", SG: "Yes"},
    {l1: "\u2011inurad", l2: "urate transporter inhibitors", level: "Undefined", SG: "No"},
    {l1: "io\u2011 e.g., iopromide ", l2: "iodine-containing contrast media", level: "Undefined", SG: "Yes"},
    {l1: "io(d)\u2011/\u2011io\u2011 e.g., iodine ioflupane (123-I) ", l2: "radio\u00ADpharma\u00ADceuticals, iodine-contained", level: "Undefined", SG: "Yes"},
    {l1: "\u2011irine (not \u2011virine)", l2: "cytotoxic pyrrolo\u00ADbenzo\u00ADdiazepine dimers & analogues", level: "Undefined", SG: "No"},
    {l1: "\u2011irudin", l2: "hirudin derivatives", level: "Blood", SG: "No"},
    {l1: "\u2011isant", l2: "histamine H3 receptor antagonists, inverse agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011isomide", l2: "class I anti\u00ADarrhythmics, disopyramide derivatives", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011ium e.g., ipratropium", l2: "quaternary ammonium compounds", level: "Foundational", SG: "Yes"},
    {l1: "\u2011ium → \u2011curonium (not an official stem) e.g., pancuronium", l2: "quaternary ammonium compounds → non-depolarising NMJ blocking agents", level: "Foundational", SG: "Yes"},
    {l1: "\u2011ixafor\u2011 e.g., plerixafor", l2: "chemokine CXCR4 antagonists", level: "Blood", SG: "Yes"},
    {l1: "\u2011ixibat e.g., elobixibat", l2: "ileal bile acid transporter (IBAT) inhibitors, bile acid reabsorption inhibitors", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011izine (\u2011yzine) e.g., cyclizine", l2: "diphenylmethyl piperazine derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011izine (\u2011yzine) e.g., cyclizine", l2: "diphenylmethyl piperazine derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011izine (\u2011yzine) → \u2011rizine e.g., cetirizine", l2: "diphenylmethyl piperazine derivatives → antihistames / vasodilators", level: "Foundational", SG: "Yes"},
    {l1: "\u2011izine (\u2011yzine) → \u2011rizine e.g., cetirizine", l2: "diphenylmethyl piperazine derivatives → antihistames / vasodilators", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011izine (\u2011yzine) → \u2011rizine e.g., cinnarizine", l2: "diphenylmethyl piperazine derivatives → antihistames / vasodilators", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011kacin e.g., amikacin", l2: "antibiotics, kanamycin & bekanamycin derivatives (from Streptomyces kanamyceticus)", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011kalant", l2: "potassium channel blockers", level: "Undefined", SG: "No"},
    {l1: "\u2011kalim", l2: "potassium channel activators, anti\u00ADhypertensive", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011kef\u2011", l2: "enkephalin, endorphin & dynorphin opioid δ, μ & κ receptor agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011kin", l2: "interleukin type substances", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011nakin", l2: "interleukin type substances → interleukin-1 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011leukin", l2: "interleukin type substances → interleukin-2 analogues & derivatives:", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011trakin", l2: "interleukin type substances → interleukin-4 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011exakin", l2: "interleukin type substances → interleukin-6 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011eptakin", l2: "interleukin type substances → interleukin-7 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011octakin", l2: "interleukin type substances → interleukin-8 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011decakin", l2: "interleukin type substances → interleukin-10 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011elvekin", l2: "interleukin type substances → interleukin-11 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011dodekin", l2: "interleukin type substances → interleukin-12 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011tredekin", l2: "interleukin type substances → interleukin-13 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011octadekin", l2: "interleukin type substances → interleukin-18 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kin → \u2011enicokin", l2: "interleukin type substances → interleukin-21 analogues & derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011kinra", l2: "interleukin receptor antagonists & interleukin antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011kinra → \u2011nakinra", l2: "interleukin receptor antagonists & interleukin antagonists → IL-1R antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011kinra → \u2011trakinra", l2: "interleukin receptor antagonists & interleukin antagonists → IL-4R antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011kiren", l2: "renin inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011laner", l2: "antagonists of GABA regulated chloride channels, antiparasitic agents", level: "Undefined", SG: "No"},
    {l1: "–leuton", l2: "5-lipo-oxygenase inhibitors, anti-inflammatory", level: "Respiratory", SG: "No"},
    {l1: "\u2011lisib e.g., idelalisib", l2: "phosph\u00ADatidyl\u00ADinositol 3-kinase inhibitors, anti\u00ADneoplastics", level: "Cancer", SG: "Yes"},
    {l1: "\u2011lubant", l2: "leukotriene B4 receptor antagonists", level: "Musculoskeletal", SG: "No"},
    {l1: "\u2011lutamide e.g., darolutamide", l2: "non-steroid antiandrogens", level: "Cancer", SG: "Yes"},
    {l1: "\u2011madlin", l2: "E3 ubiquitin-protein ligase Mdm2 (Hdm2) inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011mantadine / \u2011mantine / \u2011mantone e.g., amantadine", l2: "adamantane derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011mantadine / \u2011mantine / \u2011mantone e.g., memantine", l2: "adamantane derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011meline", l2: "cholinergic (muscarinic agonists / partial antagonists for Alzheimer’s disease)", level: "Neuroscience", SG: "No"},
    {l1: "\u2011meline", l2: "cholinergic (muscarinic agonists / partial antagonists for Alzheimer’s disease)", level: "Foundational", SG: "No"},
    {l1: "\u2011meran", l2: "messenger RNA (mRNA)", level: "Undefined", SG: "No"},
    {l1: "\u2011mesine", l2: "sigma receptor ligands", level: "Undefined", SG: "No"},
    {l1: "\u2011mestane e.g., exemestane", l2: "aromatase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011metacin e.g., indometacin", l2: "anti-inflammatory, indometacin derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011metacin e.g., indometacin", l2: "anti-inflammatory, indometacin derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011micin e.g., gentamicin", l2: "amino\u00ADglycosides, antibiotics obtained from various Micromonospora", level: "Antimicrobial", SG: "Yes"},
    {l1: "mito\u2011 (former stem no longer acknowledged by INN) e.g., mitotane", l2: "anti\u00ADneoplastics, nucleotoxic agents", level: "Cancer", SG: "Yes"},
    {l1: "\u2011monam", l2: "monobactam antibiotics", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011motine", l2: "antivirals, quinoline derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011moxin (former stem no longer acknowledged by INN)", l2: "monoamine oxidase inhibitors, hydrazine derivatives", level: "Neuroscience", SG: "No"},
    {l1: "\u2011moxin (former stem no longer acknowledged by INN)", l2: "monoamine oxidase inhibitors, hydrazine derivatives", level: "Neuroscience", SG: "No"},
    {l1: "\u2011mulin", l2: "anti\u00ADbacterials, pleuromulin derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011mustine e.g., carmustine", l2: "anti\u00ADneoplastic, alkylating agents, (b-chloroethyl)\u00ADamine derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011mycin (not tobramycin) e.g., azithromycin", l2: "antibiotics, produced by Streptomyces strains (see also -kacin)", level: "Antimicrobial", SG: "Yes"},
    {l1: "nab", l2: "cannabinoid receptors agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011nabant", l2: "cannabinoid receptors antagonists", level: "Undefined", SG: "No"},
    {l1: "nal\u2011 e.g., naloxone", l2: "opioid receptor antagonists / agonists related to normorphine", level: "Foundational", SG: "Yes"},
    {l1: "nal\u2011 e.g., naloxone", l2: "opioid receptor antagonists / agonists related to normorphine", level: "Neuroscience", SG: "Yes"},
    {l1: "nal\u2011 e.g., naloxone", l2: "opioid receptor antagonists / agonists related to normorphine", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011nepag e.g., omidenepag", l2: "prostaglandins receptors agonists, non-prostanoids", level: "Foundational", SG: "Yes"},
    {l1: "\u2011nicline e.g., varenicline", l2: "nicotinic acetylcholine receptor partial agonists / agonists", level: "Foundational", SG: "Yes"},
    {l1: "nico\u2011, nic\u2011 or ni\u2011  e.g., nicotine", l2: "nicotinic acid or nicotinoyl alcohol derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011nicate", l2: "anti\u00ADhyper\u00ADcholesterol\u00ADaemic & / or vasodilating nicotinic acid esters", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011nidazole e.g., metronidazole", l2: "antiprotozoals & radio\u00ADsensitizers, metronidazole derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "nifur\u2011 (former stem no longer acknowledged by INN) e.g., nifuroxazide", l2: "5-nitrofuran derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011nixin", l2: "anti-inflammatory, anili\u00ADnonicotinic acid derivatives", level: "Foundational", SG: "No"},
    {l1: "\u2011olol e.g., bisoprolol", l2: "β-adrenoceptor antagonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011olol e.g., timolol", l2: "β-adrenoceptor antagonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011olol e.g., bisoprolol", l2: "β-adrenoceptor antagonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011olol e.g., timolol", l2: "β-adrenoceptor antagonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011alol e.g., labetalol", l2: "β-adrenoceptor antagonists - aromatic ring -CH(OH)-CH2-NH-R related to -olols", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011onide e.g., budesonide", l2: "steroids for topical use, acetal derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011onide e.g., desonide", l2: "steroids for topical use, acetal derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011(o)nidine e.g., brimonidine", l2: "α2 adrenoreceptor agonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011(o)nidine e.g., brimonidine", l2: "α2 adrenoreceptor agonists", level: "Foundational", SG: "Yes"},
    {l1: "\u2011orex", l2: "anorexics", level: "Undefined", SG: "No"},
    {l1: "\u2011orexant e.g., lemborexant", l2: "orexin receptor antagonists", level: "Neuroscience", SG: "Yes"},
    {l1: "orphan, \u2011orph\u2011, \u2011orphine (not chlor\u00ADpheniramine) e.g., dextro\u00ADmethorphan", l2: "opioid receptor antagonists / agonists, morphinan derivates", level: "Respiratory", SG: "Yes"},
    {l1: "orphan, \u2011orph\u2011, \u2011orphine (not chlor\u00ADpheniramine) e.g., buprenorphine", l2: "opioid receptor antagonists / agonists, morphinan derivates", level: "Foundational", SG: "Yes"},
    {l1: "\u2011oxacin e.g., ciprofloxacin", l2: "anti\u00ADbacterials, nalidixic acid derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011oxan(e)", l2: "benzodioxane derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011oxetine e.g., vortioxetine", l2: "serotonin & / or norepinephrine reuptake inhibitors, fluoxetine derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pafant", l2: "platelet-activating factor antagonists", level: "Blood", SG: "No"},
    {l1: "\u2011pamide (not chlorpro\u00ADpamide) e.g., indapamide", l2: "diuretics, sulfamoyl\u00ADbenzoic acid derivatives (could be sulfamoyl\u00ADbenzamide)", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pamide (not chlorpro\u00ADpamide) e.g., indapamide", l2: "diuretics, sulfamoyl\u00ADbenzoic acid derivatives (could be sulfamoyl\u00ADbenzamide)", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pamil e.g., verapamil", l2: "calcium channel blockers, verapamil derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011parcin", l2: "glycopeptide antibiotics", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011parib e.g., talazoparib", l2: "poly-ADP-ribose polymerase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011parin e.g., enoxaparin", l2: "heparin derivatives including low molecular mass heparins", level: "Blood", SG: "Yes"},
    {l1: "\u2011parin → \u2011parinux e.g., fondaparinux", l2: "heparin derivatives including low molecular mass → synthetic heparinoids", level: "Blood", SG: "Yes"},
    {l1: "\u2011penem e.g., ertapenem", l2: "analogues of penicillanic acid antibiotics modified in the five-membered ring", level: "Antimicrobial", SG: "Yes"},
    {l1: "perfl(u)\u2011 e.g., perflubutane", l2: "perfluorinated compounds used as blood substitutes & / or diagnostic agents", level: "Blood", SG: "Yes"},
    {l1: "\u2011perone", l2: "tran\u00ADquill\u00ADizers, neuroleptics, 4’-fluoro-4\u00ADpiperidino\u00ADbutyrophenone derivatives", level: "Neuroscience", SG: "No"},
    {l1: "\u2011peridol e.g., droperidol", l2: "anti\u00ADpsychotics, haloperidol derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011peridone e.g., paliperidone", l2: "anti\u00ADpsychotics, risperidone derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pidem e.g., zolpidem", l2: "hypnotics / sedatives, zolpidem derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pin(e) → \u2011zepine e.g., carbamazepine", l2: "tricyclic compounds → antidepressant / neuroleptic / antiulcer / anticonvulsant", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pin(e) → \u2011apine (not noscapine) e.g., quetiapine", l2: "tricyclic compounds → psychoactive", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pin(e) → \u2011cilpine", l2: "tricyclic compounds → antiepileptic", level: "Neuroscience", SG: "No"},
    {l1: "\u2011piprant", l2: "prostaglandin receptors antagonists, non-prostanoids", level: "Undefined", SG: "No"},
    {l1: "\u2011plact", l2: "platelet factor 4 analogues & derivatives", level: "Blood", SG: "No"},
    {l1: "\u2011pladib", l2: "phospholipase A2 inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011planin", l2: "glycopeptide antibacterials (Actinoplanes strains)", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011platin e.g., cisplatin", l2: "antineoplastic agents, platinum derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011plon", l2: "imidazo- or pyrazolo\u00ADpyrimidine derivative anxiolytics, sedatives, hypnotics", level: "Neuroscience", SG: "No"},
    {l1: "\u2011poetin e.g., epoetin alfa", l2: "erythropoietin type blood factors", level: "Blood", SG: "Yes"},
    {l1: "\u2011porfin e.g., verteporfin", l2: "benzoporphyrin derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011(p)oride (not chloride or fluoride) e.g., amiloride", l2: "Na+/H+ antiport inhibitor", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pramine e.g., clomipramine", l2: "substances of the imipramine group", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011prazan e.g., vonoprazan", l2: "proton pump inhibitors, not dependent on acid activation", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011prazole (not \u2011piprazole) e.g., omeprazole", l2: "antiulcer, benzimidazole derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011piprazole (use discouraged due to conflict with \u2011prazole) e.g., brexpiprazole", l2: "psychotropics, phenyl\u00ADpiperazine derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "pred e.g., methyl\u00ADprednisolone", l2: "prednisone & prednisolone derivatives", level: "Foundational", SG: "Yes"},
    {l1: "pred e.g., methyl\u00ADprednisolone", l2: "prednisone & prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "pred e.g., methyl\u00ADprednisolone", l2: "prednisone & prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011methasone or \u2011metasone (not an official stem) e.g., clobetasol ", l2: "prednisone & prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011betasol (not an official stem) e.g., dexamethasone", l2: "prednisone & prednisolone derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011betasol (not an official stem) e.g., dexamethasone", l2: "prednisone & prednisolone derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011betasol (not an official stem) e.g., dexamethasone", l2: "prednisone & prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011betasol (not an official stem) e.g., dexamethasone", l2: "prednisone & prednisolone derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011pressin e.g., desmopressin", l2: "vasopressin analogues", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pressin e.g., desmopressin", l2: "vasopressin analogues", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pride e.g., prucalopride", l2: "sulpiride derivatives & analogues", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011pride e.g., amisulpride", l2: "sulpiride derivatives & analogues", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011pril e.g., captopril", l2: "angiotensin-converting enzyme inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011pril → \u2011prilat", l2: "angiotensin-converting enzyme inhibitors → diacid analogs of the -pril entity", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011prim e.g., trimethoprim", l2: "anti\u00ADmicrobials, dihydrofolate reductase inhibitors, trimethoprim analogues", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011pris\u2011 e.g., mifepristone", l2: "steroidal compounds acting on progesterone receptors (excl. -gest- compounds)", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011pristin", l2: "anti\u00ADbacterials, strepto\u00ADgramins, protein\u00ADsynthesis inhibitors, pristina\u00ADmycin derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011prodil", l2: "N-methyl-D-aspartate (NMDA) receptor antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011profen (not \u2011profene) e.g., ketoprofen", l2: "anti-inflammatory agents, ibuprofen derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011profen (not \u2011profene) e.g., ketoprofen", l2: "anti-inflammatory agents, ibuprofen derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "prost e.g., latanoprost", l2: "prostaglandins", level: "Foundational", SG: "Yes"},
    {l1: "prost e.g., misoprostol", l2: "prosta\u00ADglandins", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011prostil", l2: "prosta\u00ADglandins, anti-ulcer", level: "Gastrointestinal", SG: "No"},
    {l1: "\u2011(o)pterin e.g., sapropterin", l2: "pteridine derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011quidar", l2: "drugs used in multidrug resistance; quinoline derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011quine e.g., chloroquine", l2: "quinoline derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011quine e.g., hydroxy\u00ADchloroquine", l2: "quinoline derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011racetam e.g., piracetam", l2: "amide type nootrope agents, piracetam derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011racil", l2: "uracil type anti\u00ADneoplastics", level: "Cancer", SG: "No"},
    {l1: "\u2011thiouracil e.g., propyl\u00ADthiouracil ", l2: "uracil derivatives used as thyroid antagonists", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011rafenib", l2: "Raf (rapidly accelerated fibrosarcoma) kinase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011relin e.g., triptorelin ", l2: "pituitary hormone-release stimulating peptides", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011relin e.g., goserelin", l2: "pituitary hormone-release stimulating peptides", level: "Cancer", SG: "Yes"},
    {l1: "\u2011relin → \u2011morelin", l2: "pituitary hormone- → growth hormone release-stimulating peptides", level: "Endocrine", SG: "No"},
    {l1: "\u2011relin → \u2011tirelin", l2: "pituitary hormone- → thyrotropin releasing hormone analogues", level: "Endocrine", SG: "No"},
    {l1: "\u2011relix e.g., ganirelix ", l2: "gonadotropin-releasing hormone (GnRH) inhibitors, peptides", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011renone e.g., drospirenone", l2: "mineralo\u00ADcorticoid receptor (MR, MCR, aldosterone receptor) antagonists", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011renone e.g., eplerenone ", l2: "mineralo\u00ADcorticoid receptor (MR, MCR, aldosterone receptor) antagonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "retin (not ripretinib) e.g., acitretin", l2: "retinol derivatives", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011ribine e.g., cladribine", l2: "ribofuranyl-derivatives of the “pyrazofurin” type", level: "Neuroscience", SG: "Yes"},
    {l1: "rifa\u2011 e.g., rifampicin", l2: "antibiotics, rifamycin derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011rinone e.g., milrinone", l2: "cardiac stimulants, amrinone derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011rixin", l2: "chemokine CXCR receptors antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011rozole e.g., letrozole", l2: "aromatase inhibitors, imidazole-triazole derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011rsen", l2: "antisense oligo\u00ADnucleo\u00ADtides", level: "Undefined", SG: "No"},
    {l1: "\u2011rsen → \u2011nersen e.g., nusinersen", l2: "antisense oligo\u00ADnucleo\u00ADtides → targeting neurological functions", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011rubicin e.g., epirubicin", l2: "anti\u00ADneoplastics, daunorubicin derivatives", level: "Cancer", SG: "Yes"},
    {l1: "sal [not broncho\u00ADdilators (\u2011)sal\u2011x\u2011ol] e.g., salicylamide", l2: "salicylic acid derivatives", level: "Foundational", SG: "Yes"},
    {l1: "\u2011sartan e.g., valsartan", l2: "angiotensin II receptor antagonists, anti\u00ADhypertensive (non-peptidic)", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011semide e.g., furosemide", l2: "diuretics, furosemide derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011semide e.g., furosemide", l2: "diuretics, furosemide derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011serod", l2: "serotonin receptor antagonists & partial agonists", level: "Undefined", SG: "No"},
    {l1: "\u2011serpine (former stem no longer acknowledged by INN)", l2: "derivatives of Rauwolfia alkaloids", level: "Undefined", SG: "No"},
    {l1: "\u2011sertib", l2: "serine / threonine kinase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011setron e.g., ondansetron", l2: "5-HT3 antagonists not fitting into other groups of 5-HT receptor antagonists", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011siban", l2: "oxytocin antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011sidenib", l2: "isocitrate dehydrogenase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011siran e.g., inclisiran ", l2: "small interfering RNA including siRNA, miRNA & piRNA", level: "Cardiovascular", SG: "Yes"},
    {l1: "som\u2011 (not somatostatin) e.g., somatrogon", l2: "growth hormone derivatives", level: "Endocrine", SG: "Yes"},
    {l1: "som\u2011 → \u2011bove", l2: "growth hormone derivatives → bovine", level: "Endocrine", SG: "No"},
    {l1: "som\u2011 → \u2011por", l2: "growth hormone derivatives → porcine", level: "Endocrine", SG: "No"},
    {l1: "som\u2011 → \u2011salm", l2: "growth hormone derivatives → salmon", level: "Endocrine", SG: "No"},
    {l1: "\u2011spirone", l2: "anxiolytics, buspirone derivatives", level: "Neuroscience", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat (not nystatin or somatostatin) e.g., febuxostat", l2: "enzyme inhibitors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011vastatin e.g., atorvastatin", l2: "enzyme inhibitors → anti\u00ADhyper\u00ADlipid\u00ADaemic substances, HMG CoA reductase inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011castat", l2: "enzyme inhibitors → dopamine β-hydroxylase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011dustat", l2: "enzyme inhibitors → hypoxia inducible factor (HIF) prolyl hydroxylase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011elestat", l2: "enzyme inhibitors → elastase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011gacestat", l2: "enzyme inhibitors → gamma-secretase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011inostat", l2: "enzyme inhibitors → histone deacetylase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011listat e.g., orlistat", l2: "enzyme inhibitors → gastro\u00ADintestinal lipase inhibitors", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011mastat", l2: "enzyme inhibitors → matrix metallo\u00ADprotein\u00ADase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011mostat", l2: "enzyme inhibitors → proteolytic enzyme inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011restat or \u2011restat\u2011", l2: "enzyme inhibitors → aldose reductase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011demstat", l2: "enzyme inhibitors → lysine-specific histone demethylase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011becestat", l2: "enzyme inhibitors → beta secretase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011glustat", l2: "enzyme inhibitors → ceramide gluco\u00ADsyl\u00ADtransferase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011stat\u2011 or \u2011stat → \u2011metostat", l2: "enzyme inhibitors → histone N-methyl\u00ADtransferase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011steine e.g., carbocisteine", l2: "mucolytics, other than bromhexine derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011ster\u2011 (not \u2011gest\u2011, \u2011gesterone or \u2011steride) e.g., testosterone", l2: "androgens / anabolic steroids", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011steride e.g., dutasteride ", l2: "testosterone reductase inhibitors", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011steride e.g., dutasteride ", l2: "testosterone reductase inhibitors", level: "Reproductive / Urogenital", SG: "Yes"},
    {l1: "\u2011stigmine (former stem no longer acknowledged by INN) e.g., rivastigmine", l2: "acetyl\u00ADcholin\u00ADesterase inhibitors", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011stigmine (former stem no longer acknowledged by INN) e.g., neostigmine", l2: "acetyl\u00ADcholin\u00ADesterase inhibitors", level: "Foundational", SG: "Yes"},
    {l1: "\u2011stim e.g., romiplostim", l2: "colony stimulating factors (CSFs)", level: "Blood", SG: "Yes"},
    {l1: "\u2011stim → \u2011distim", l2: "CSFs → combination of two different types of colony stimulating factors", level: "Blood", SG: "No"},
    {l1: "\u2011stim → \u2011gramostim", l2: "CSFs → granulocyte macrophage colony stimulating factor (GM-CSF) types substances", level: "Blood", SG: "No"},
    {l1: "\u2011stim → \u2011grastim e.g., pegfilgrastim", l2: "CSFs → granulocyte colony stimulating factor (G-CSF) type substances", level: "Blood", SG: "Yes"},
    {l1: "\u2011stim → \u2011mostim", l2: "CSFs → macrophage stimulating factors (M-CSF) type substances", level: "Blood", SG: "No"},
    {l1: "\u2011stim → \u2011plestim", l2: "CSFs → interleukin-3 analogues & derivatives", level: "Blood", SG: "No"},
    {l1: "\u2011stinel", l2: "N-methly-D-aspartate (NMDA) receptor co-agonists", level: "Undefined", SG: "No"},
    {l1: "sulfa\u2011 (not sulfars\u00ADphen\u00ADamine) e.g., sulfasalazine", l2: "anti-infectives, sulfon\u00ADamides", level: "Musculoskeletal", SG: "Yes"},
    {l1: "sulfa\u2011 (not sulfars\u00ADphen\u00ADamine) e.g., sulfasalazine", l2: "anti-infectives, sulfon\u00ADamides", level: "Gastrointestinal", SG: "Yes"},
    {l1: "sulfa\u2011 (not sulfars\u00ADphen\u00ADamine) e.g., sulfa\u00ADmeth\u00ADoxa\u00ADzole", l2: "anti-infectives, sulfon\u00ADamides", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011sulfan e.g., busulfan", l2: "anti\u00ADneoplastic, alkylating agents, methane\u00ADsulfonates", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tadine (not amantadine) e.g., loratadine", l2: "histamine H1 receptor antagonists, tricyclic compounds", level: "Foundational", SG: "Yes"},
    {l1: "\u2011tadine (not amantadine) e.g., loratadine", l2: "histamine H1 receptor antagonists, tricyclic compounds", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011tadine (not amantadine) e.g., olopatadine", l2: "histamine H1 receptor antagonists, tricyclic compounds", level: "Foundational", SG: "Yes"},
    {l1: "\u2011tansine e.g., trastuzumab emtansine", l2: "maytansinoid derivatives, anti\u00ADneoplastics", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tant → \u2011pitant e.g., netupitant", l2: "neurokinin (tachykinin) antagonists → neurokinin NK1 (substance P) antagonist", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011tant → \u2011dutant", l2: "neurokinin (tachykinin) antagonists → neurokinin NK2 receptor antagonist", level: "Undefined", SG: "No"},
    {l1: "\u2011tant → \u2011nertant", l2: "neurokinin (tachykinin) antagonists → neurotensin receptor antagonist", level: "Undefined", SG: "No"},
    {l1: "\u2011tant → \u2011netant", l2: "neurokinin (tachykinin) antagonists → NK3 & dual NK3-NK1 receptor antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011tapide", l2: "microsomal triglyceride transfer protein (MTP) inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011taxel e.g., docetaxel", l2: "anti\u00ADneoplastics, taxane derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tecan e.g., topotecan", l2: "anti\u00ADneoplastics, topoisomerase I inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tepa e.g., thiotepa", l2: "anti\u00ADneoplastics, thiotepa derivatives", level: "Cancer", SG: "Yes"},
    {l1: "\u2011terol e.g., formoterol", l2: "broncho\u00ADdilators, phenethylamine derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "\u2011terone (not \u2011gesterone) e.g., abiraterone", l2: "antiandrogens", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tiazem e.g., diltiazem", l2: "calcium channel blockers, diltiazem derivatives", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011tibant", l2: "bradykinin receptors antagonists", level: "Respiratory", SG: "No"},
    {l1: "\u2011tide", l2: "peptides & glycopeptides", level: "Undefined", SG: "No"},
    {l1: "\u2011tide → \u2011glutide e.g., liraglutide", l2: "glucagon-like peptide (GLP) analogues & agonists", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011tide → \u2011motide", l2: "peptides & glycopeptides → immunological agents for active immunization", level: "Undefined", SG: "No"},
    {l1: "\u2011tide → \u2011reotide e.g., octreotide", l2: "peptides & glycopeptides → somatostatin receptor agonists / antagonists", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011tide → \u2011ritide", l2: "peptides & glycopeptides → natriuretic peptides", level: "Undefined", SG: "No"},
    {l1: "\u2011tide → \u2011paratide (not an official INN stem) e.g., teriparatide", l2: "peptides & glycopeptides → parathyroid hormone -like peptides", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011tide → \u2011dutide", l2: "peptides & glycopeptides → oxyntomodulin analogues & dual agonists of GLP-1R & GCGR", level: "Undefined", SG: "No"},
    {l1: "\u2011tide → \u2011enatide e.g., lixisenatide", l2: "peptides & glycopeptides → GLP1R agonists, exenatide (exendin-4) & analogues", level: "Endocrine", SG: "Yes"},
    {l1: "\u2011tide → \u2011netide", l2: "peptides & glycopeptides → neurological", level: "Neuroscience", SG: "No"},
    {l1: "\u2011tide → \u2011pultide", l2: "peptides & glycopeptides → parathyroid hormone -like peptides", level: "Respiratory", SG: "No"},
    {l1: "\u2011tidine e.g., famotidine", l2: "histamine H2-receptor antagonists, cimetidine derivatives", level: "Gastrointestinal", SG: "Yes"},
    {l1: "\u2011tinib", l2: "tyrosine kinase inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011tinib → \u2011brutinib e.g., zanubrutinib", l2: "tyrosine kinase inhibitors →  agamma\u00ADglobulinaemia (Bruton) tyrosine kinase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tinib → \u2011citinib e.g., baricitinib", l2: "tyrosine kinase inhibitors → Janus kinase inhibitors", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011tinib → \u2011metinib e.g., binimetinib", l2: "tyrosine kinase inhibitors → MEK (MAPK kinase) tyrosine kinase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tinib → \u2011ertinib e.g., osimertinib", l2: "tyrosine kinase inhibitors → epidermal growth factor receptor (EGFR) inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tinib → \u2011trectinib e.g., larotrectinib", l2: "tyrosine kinase inhibitors → tropomyosin receptor kinase (TRK) inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tirom(\u2011)", l2: "anti\u00ADhyper\u00ADlidaemics, thyromimetic derivatives", level: "Cardiovascular", SG: "No"},
    {l1: "\u2011tizide", l2: "diuretics, chlorothiazide derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011tocin e.g., carbetocin", l2: "oxytocin derivatives", level: "Blood", SG: "Yes"},
    {l1: "\u2011tocin e.g., carbetocin", l2: "oxytocin derivatives", level: "Reproductive / Urogential", SG: "Yes"},
    {l1: "\u2011toclax e.g., venetoclax", l2: "B-cell lymphoma 2 (Bcl-2) inhibitors, anti\u00ADneoplastics", level: "Cancer", SG: "Yes"},
    {l1: "\u2011toin (no longer acknowledged by INN) [not nirofurantoin] e.g., phenytoin", l2: "anti\u00ADepileptics, hydantoin derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011traline e.g., sertraline", l2: "serotonin reuptake inhibitors", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011trep", l2: "transient receptor potential antagonists", level: "Undefined", SG: "No"},
    {l1: "\u2011trexate e.g., methotrexate", l2: "folic acid analogues", level: "Musculoskeletal", SG: "Yes"},
    {l1: "\u2011trexate e.g., methotrexate", l2: "folic acid analogues", level: "Cancer", SG: "Yes"},
    {l1: "\u2011trexed e.g., pemetrexed", l2: "anti\u00ADneoplastics; thymidylate synthetase inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011tricin", l2: "antibiotics, polyene derivatives", level: "Antimicrobial", SG: "No"},
    {l1: "\u2011trigine e.g., lamotrigine", l2: "sodium channel blockers, signal transduction modulators", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011trigine e.g., lamotrigine", l2: "sodium channel blockers, signal transduction modulators", level: "Neuroscience", SG: "Yes"},
    {l1: "tril/trilat e.g., racecadotril", l2: "endopeptidase inhibitors", level: "Gastrointestinal", SG: "Yes"},
    {l1: "tril/trilat e.g., sacubitril", l2: "endopeptidase inhibitors", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011triptan e.g., sumatriptan", l2: "serotonin (5-HT1) receptor agonists, sumatriptan derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011triptyline e.g., nortriptyline", l2: "anti\u00ADdepressants, dibenzo\u00ADcyclo- & cyclopheptene derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011troban", l2: "thromboxane A2-receptor antagonists; antithrombotic agents", level: "Blood", SG: "No"},
    {l1: "\u2011trombopag e.g., eltrombopag", l2: "thrombopoietin agonists", level: "Blood", SG: "Yes"},
    {l1: "trop (including \u2011tropine but not \u2011tropin) e.g., tiotropium", l2: "atropine derivatives", level: "Foundational", SG: "Yes"},
    {l1: "trop (including \u2011tropine but not \u2011tropin) e.g., tiotropium", l2: "atropine derivatives", level: "Respiratory", SG: "Yes"},
    {l1: "trop (including \u2011tropine but not \u2011tropin) e.g., benztropine", l2: "atropine derivatives", level: "Neuroscience", SG: "Yes"},
    {l1: "\u2011turev", l2: "under the advanced therapy scheme for: oncolytic viruses", level: "Undefined", SG: "No"},
    {l1: "\u2011uridine e.g., trifluridine", l2: "uridine derivatives used as antiviral agents & as anti\u00ADneoplastics", level: "Cancer", SG: "Yes"},
    {l1: "\u2011vudine e.g., zidovudine", l2: "uridine derivatives used as antiviral agents & as anti\u00ADneoplastics", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011vaptan e.g., tolvaptan", l2: "vasopressin receptor antagonists", level: "Cardiovascular", SG: "Yes"},
    {l1: "\u2011verine e.g., mebeverine", l2: "spasmolytics with a papaverine-like action", level: "Gastrointestinal", SG: "Yes"},
    {l1: "vin\u2011 and \u2011vin\u2011 e.g., vinpocetine", l2: "vinca alkaloids", level: "Neuroscience", SG: "Yes"},
    {l1: "vin\u2011 and \u2011vin\u2011 e.g., vinorelbine", l2: "vinca alkaloids", level: "Cancer", SG: "Yes"},
    {l1: "vir e.g., baloxavir marboxil", l2: "antivirals (undefined group)", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011amivir e.g., zanamivir", l2: "antivirals (undefined group) → neuraminidase inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011asvir e.g., ledipasvir", l2: "antivirals (undefined group) → hepatitis C Virus (HCV) NS5A inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011buvir e.g., sofosbuvir", l2: "antivirals (undefined group) → RNA polymerase (NS5B) inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011cavir e.g., abacavir", l2: "antivirals (undefined group) → carbocyclic nucleosides", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011ciclovir / \u2011cyclovir e.g., ganciclovir", l2: "antivirals (undefined group) → bicyclic heterocycle compounds", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011fovir e.g., tenofovir alafenamide", l2: "antivirals (undefined group) → phosphonic acid derivatives", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011gosivir e.g., ritonavir", l2: "antivirals (undefined group) → glucoside inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011navir e.g., atazanavir", l2: "antivirals (undefined group) → HIV protease inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011previr e.g., voxilaprevir", l2: "antivirals (undefined group) → Hepatitis Virus C (HVC) protease inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011tegravir e.g., cabotegravir", l2: "antivirals (undefined group) → HIV integrase inhibitors", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011virine e.g., doravirine", l2: "antivirals (undefined group) → NNRTIs", level: "Antimicrobial", SG: "Yes"},
    {l1: "vir → \u2011viroc e.g., maraviroc", l2: "antivirals (undefined group) → CCR5 receptor antagonists", level: "Antimicrobial", SG: "Yes"},
    {l1: "\u2011vivint", l2: "Wnt signaling inhibitors", level: "Undefined", SG: "No"},
    {l1: "\u2011xaban e.g., rivaroxaban", l2: "blood coagulation factor XA inhibitors, anti\u00ADthrombotics", level: "Blood", SG: "Yes"},
    {l1: "\u2011xetan e.g., tetraxetan", l2: "chelating agents", level: "Undefined", SG: "Yes"},
    {l1: "\u2011zafone", l2: "alozafone derivatives", level: "Undefined", SG: "No"},
    {l1: "\u2011zolid e.g., tedizolid", l2: "oxazolidinone antibacterials", level: "Antimicrobial", SG: "Yes"},
    {l1: "zomib e.g., bortezomib", l2: "proteasome inhibitors", level: "Cancer", SG: "Yes"},
    {l1: "\u2011zotan", l2: "5-HT1A receptor agonists / antagonists acting primarily as neuro\u00ADprotectors", level: "Undefined", SG: "No"},
                 
]