import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reset: false,
};

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    triggerReset: (state) => {
      state.reset = true;
    },
    resetCompleted: (state) => {
      state.reset = false;
    },
  },
});

export const { triggerReset, resetCompleted } = resetSlice.actions;

export default resetSlice.reducer;
