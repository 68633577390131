import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSwitchState } from '../Redux/switchSlice'; // Import setSwitchState
import ToggleSwitch from './ToggleSwitch'; // Import ToggleSwitch
import ExpandSwitch from './ExpandSwitch';
import CheckboxList from './CheckboxList';
import { FormControl, FormControlLabel, Button } from "@mui/material";
import { triggerReset } from '../Redux/resetSlice';
import { setExpandState } from '../Redux/expandSlice';


const LevelSelect = ({handleCloseDrawer, handleShowWarning }) => {
  const dispatch = useDispatch();
  const currentSwitchState = useSelector((state) => state.switch.switchState);
  const currentExpandState = useSelector((state) => state.expand.switchState);
  //const checkboxItems = useSelector((state) => state.checkboxes.items);
  //const checkboxes = useSelector((state) => state.checkboxes);
  const checkboxListRef = useRef(null);

  // Local state for switches
  const [localSwitchState, setLocalSwitchState] = useState(currentSwitchState);
  const [localExpandState, setLocalExpandState] = useState(currentExpandState);

  useEffect(() => {
    // Initialize local state from Redux state
    setLocalSwitchState(currentSwitchState);
    setLocalExpandState(currentExpandState);
  }, [currentSwitchState, currentExpandState]);

  const handleSwitchChange = (newSwitchState) => {
    setLocalSwitchState(newSwitchState); // Update local state
  };

  const handleExpandChange = (newExpandState) => {
    setLocalExpandState(newExpandState); // Update local state
  };

  const handlePushAllCheckboxes = () => {
    if (checkboxListRef.current) {
      checkboxListRef.current.pushAllCheckboxes();
    }
  };

  const handleGetLocalItems = () => {
    if (checkboxListRef.current) {
      const localItems = checkboxListRef.current.getLocalItems();
      return localItems;
    }
  };

  const handleConfirm = () => {
    const levels = handleGetLocalItems()
    .filter((item) => item.checked)
    .map((item) => item.label);

    //Check minimum of 1 level selected
    if (levels.length > 0) {
      console.log('At least 1 item selected, proceeding...');
      if (typeof handleCloseDrawer === 'function') {
        handleCloseDrawer(); // Call handleCloseDrawer to close the drawer
        handlePushAllCheckboxes();
        dispatch(setSwitchState(localSwitchState)); // Update Redux state
        dispatch(setExpandState(localExpandState)); // Update Redux state
        dispatch(triggerReset());
      } else {
        console.error('handleCloseDrawer is not a function');
      }
    } else {
      console.log('No levels selected, showing pop-up');
      return handleShowWarning();
    }
    
  };

  return (
    <div>
      <CheckboxList ref={checkboxListRef} />
      <FormControl component="fieldset" 
      sx={{
        marginLeft: "14px"
      }}>
        <FormControlLabel
          control={
            <ToggleSwitch
              localSwitchState={localSwitchState}
              onSwitchChange={handleSwitchChange}
            />
          }
        />
        <FormControlLabel
          control={
            <ExpandSwitch
              localSwitchState={localExpandState}
              onSwitchChange={handleExpandChange}
            />
          }
        />
      </FormControl>
      <Button 
      sx={{
        backgroundColor: "#003D7C",
        fontFamily: "Poppins",
        textTransform: "None",

      }}
      variant="contained" onClick={() => handleConfirm()}>Generate Deck</Button>
    </div>
  );
};

export default LevelSelect;
