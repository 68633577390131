import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
//import { useSelector } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { toggleCheckbox } from "../Redux/checkboxSlice";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import '../index.css';

const CheckboxList = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.checkboxes.items);

  const [localItems, setLocalItems] = useState(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  useImperativeHandle(ref, () => ({
    pushAllCheckboxes() {
      localItems.forEach((newItem) => {
        const correspondingItem = items.find(item => item.id === newItem.id);
        if (correspondingItem && correspondingItem.checked !== newItem.checked) {
          dispatch(toggleCheckbox({ id: newItem.id }));
        }
      });
    },
    getLocalItems() {
      return localItems;
    }
  }));

  const handleToggleCheckbox = (id) => {
    //dispatch(toggleCheckbox({ id }));
    const updatedItems = localItems.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setLocalItems(updatedItems);

  };

  return (
    <div>
      {localItems.map((item) => (
        <div key={item.id}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  marginLeft: "3px",
                  padding: "4px",
                  color: "#003D7C",
                  "&.Mui-checked": {
                      color: "#003D7C",
                  }
                }}
                checked={item.checked}
                onChange={() => handleToggleCheckbox(item.id)}
              />
            }
            label={<Typography fontSize="14px" fontFamily="Poppins" color="#003D7C"> {item.label} </Typography>}
          />
        </div>
      ))}
    </div>
  );
});

export default CheckboxList;
