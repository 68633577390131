// Import the configureStore function from Redux Toolkit
import { configureStore } from '@reduxjs/toolkit';
// Import the checkboxReducer from the checkboxSlice module
import checkboxReducer from './checkboxSlice';
import switchReducer from './switchSlice';
import resetReducer from './resetSlice';
import expandReducer from './expandSlice';

// Export the configured store
export default configureStore({
    // Define the reducers for the store
    reducer: {
        // Assign the checkboxReducer to handle state updates for the 'checkboxes' slice of the state
        reset: resetReducer,
        checkboxes: checkboxReducer,
        switch: switchReducer,
        expand: expandReducer
    },
});
