import { FormControl, FormControlLabel, Switch } from "@mui/material";

const ToggleSwitch = ({ localSwitchState, onSwitchChange }) => {
  // Handle switch state changes
  const handleSwitchChange = (event) => {
    const newSwitchState = event.target.checked;
    onSwitchChange(newSwitchState);
  };

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          <Switch
            checked={localSwitchState}
            onChange={handleSwitchChange}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: localSwitchState ? '#003D7C' : undefined,
                '&:hover': {
                  backgroundColor: localSwitchState ? 'rgba(255, 0, 0, 0.08)' : undefined,
                },
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: localSwitchState ? '#003D7C' : undefined,
              },
            }}
          />
        }
        label={
          <span style={{ fontSize: '0.9em', color: '#003D7C'}}>
            {localSwitchState ? "WHO Stem Book" : "Limited to SG NDF"}
          </span>
        }
      />
    </FormControl>
  );
};

export default ToggleSwitch;
