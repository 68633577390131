import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom"; // Importing necessary components from react-router-dom for routing.
import { Memory } from "./Memory"; // Importing the Memory component.
import { Header } from "./Header"; // Importing the Header component.
import { Footer } from "./Footer"; // Importing the Footer component.
/*import { changeLevels } from "./changeLevels";*/ // Importing the ChangeLevels component (assuming it will be used in the future).

// The Main component serves as the primary structure of the application.
export const Main = () => {
  return (
    <div>
      <Header /> {/* Rendering the Header component at the top of the application. */}
      <BrowserRouter> {/* Enabling routing functionality within the application. */}
        <Switch> {/* The Switch component renders the first child <Route> that matches the location. */}
          <Route exact path="/"> {/* Defining a route that matches the root path. */}
            <Memory /> {/* Rendering the Memory component when the root path is accessed. */}
          </Route>
        </Switch>
      </BrowserRouter>
      <Footer /> {/* Rendering the Footer component at the bottom of the application. */}
    </div>
  );
};

