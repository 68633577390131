import React from "react";
import { Modal, Fade, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Simplified and improved styling for the modal using MUI's Box component
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.5em",
  border: "0.1em solid darkgrey",
  margin: "0",
  paddingBottom: "1em",
  zIndex: 0,
};

// Functional component for the modal
export default function FBModal({ prompt, title, body }) {
  // State to manage the modal's open/close status
  const [open, setOpen] = React.useState(false);

  // Handlers to open and close the modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Header component for the modal
  const Header = () => (
    <Box
      sx={{
        zIndex: 0,
        padding: 0,
        margin: 0,
        width: "100%",
        height: "6vh",
        borderRadius: "0.375em 0.375em 0 0",
        backgroundColor: "#003D7C",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h1
        style={{
          fontWeight: 600,
          fontSize: "14pt",
          color: "#EF7C00",
          marginLeft: "1em",
        }}
      >
        {title}
      </h1>
      <CloseIcon
        onClick={handleClose}
        style={{
          color: "#EF7C00",
          marginRight: "0.5em",
          cursor: "pointer",
        }}
      />
    </Box>
  );

  return (
    <>
      {/* Trigger to open the modal */}
      <span onClick={handleOpen}>{prompt}</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Header />
            {body}
            <center>
              {/* Button to close the modal */}
              <Button
                size="large"
                variant="contained"
                sx={{
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  textTransform: "none",
                  backgroundColor: "#003D7C",
                  "&:hover": {
                    backgroundColor: "#EF7C00",
                  },
                }}
                onClick={handleClose}
              >
                Play
              </Button>
            </center>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

