import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  switchState: true,
};

const switchSlice = createSlice({
  name: 'switch',
  initialState,
  reducers: {
    toggleSwitch: (state) => {
      state.switchState = !state.switchState;
    },
    setSwitchState: (state, action) => {
      state.switchState = action.payload;
      console.log("WHO drug list selected: ", state.switchState);
    },
  },
});

export const { toggleSwitch, setSwitchState } = switchSlice.actions;

export default switchSlice.reducer;
