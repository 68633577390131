import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  switchState: true,
};

const expandSlice = createSlice({
  name: 'expand',
  initialState,
  reducers: {
    toggleExpand: (state) => {
      state.switchState = !state.switchState;
    },
    setExpandState: (state, action) => {
      state.switchState = action.payload;
      console.log("Expanded drug list selected: ", state.switchState);
    },
  },
});

export const { toggleExpand, setExpandState } = expandSlice.actions;

export default expandSlice.reducer;
