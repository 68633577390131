import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [
    { id: 1, label: "Foundational", checked: true },
    { id: 2, label: "Antimicrobial", checked: true },
    { id: 3, label: "Cancer", checked: true },
    { id: 4, label: "Blood", checked: true },
    { id: 5, label: "Respiratory", checked: true },
    { id: 6, label: "Cardiovascular", checked: true },
    { id: 7, label: "Gastrointestinal", checked: true },
    { id: 8, label: "Endocrine", checked: true },
    { id: 9, label: "Reproductive / Urogenital", checked: true },
    { id: 10, label: "Musculoskeletal", checked: true },
    { id: 11, label: "Neuroscience", checked: true },
  ],
};

const checkboxSlice = createSlice({
  name: 'checkboxes',
  initialState,
  reducers: {
    toggleCheckbox: (state, action) => {
      const { id } = action.payload;
      const item = state.items.find((item) => item.id === id);
      if (item) {
        item.checked = !item.checked;
        console.log(item.label + ": " + item.checked);
      }
      return state; // Return the updated state
    }
  },
});

export const { toggleCheckbox } = checkboxSlice.actions;

export default checkboxSlice.reducer;
