import { Main } from "./components/Main";
import { Provider } from "react-redux";
import store from "./Redux/store";
/*import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Memory2 } from "./components/Memory2";*/
import WebFont from 'webfontloader';

// Load Google Fonts
WebFont.load({
  google: {
    families: ['Poppins:300,400,500,600,700', 'sans-serif']
  }
});

/**
 * App component serves as the root component of the application.
 * It loads necessary fonts and returns the Main component.
 * @returns {JSX.Element} The main component of the app.
 */
function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App;
