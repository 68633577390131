// Import the orange color from Material-UI's color palette
/*import { orange } from "@mui/material/colors";*/

// Import the React library for creating components
import React from "react";

// Export the Footer component as a named export
export const Footer = () => {
  return (
    // The footer container with a CSS class 'footer' for styling
    <div className="footer">
      {/* The span element for positioning the support link */}
      <span
        style={{
          position: "fixed", // Fixes the span to the specified position on the screen
          right: "5px", // Positions the span 5px from the right edge of the screen
          bottom: "3px", // Positions the span 3px from the bottom edge of the screen
          paddingRight: "5px", // Adds 5px padding to the right of the span
          fontSize: "small", // Sets the font size to small
        }}
      >
        {/* The anchor tag with a link to support the application */}
        <a 
          target="_blank" // Opens the link in a new tab
          href="https://buymeacoffee.com/pharmanus" // URL for supporting the application
          className="footer-link" // CSS class for styling the link
          rel="noopener noreferrer"  // Add this attribute
        >
          Support PharMatch
        </a>
      </span>
    </div>
  );
};
