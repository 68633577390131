import React, { useState } from "react";
import FBModal from "./FBModal";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import QuestionMarkIcon from "@mui/icons-material/HelpRounded";
import InfoIcon from "@mui/icons-material/InfoRounded";
import LevelSelect from "./LevelSelect"; // Import LevelSelect component
import CloseIcon from "@mui/icons-material/Close";
import { styled, Backdrop, Fade, Modal  } from "@mui/material";

// Modal styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  border: "2px solid darkgrey",
  minHeight: "200px",
  padding: "0 0 1.25em 0",
};

// Styled Backdrop to prevent clicks outside of the modal
const StyledBackdrop = styled(Backdrop)({
  pointerEvents: 'none', // Prevents clicking on the backdrop
});

export const Header = () => {
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const handleCloseDrawer = () => {
    setShow(false);
  };

  const handleCloseWarning = () => setShowWarning(false);
  
  const handleShowWarning = () => setShowWarning(true);

  return (
    <>
      <div className="header">
        <Fab
          sx={{
            zIndex: 3,
            width: 40,
            height: 40, 
            backgroundColor: "white",
            fontWeight: 600,
            color: "#003D7C",
            position: "absolute",
            left: "20px",
            top: "16px",
          }}
          className="menufab"
          onClick={() => setShow(!show)} 
        >
          {!show ? (
            <MenuIcon fontSize="large" style={{ transform: "scale(1)" }} />
          ) : (
            <MenuOpenIcon
              fontSize="large"
              style={{ transform: "scale(1)" }}
            />
          )}
        </Fab>

        <div>
          <h1
            style={{
              fontWeight: "600",
              fontFamily: "Poppins",
              width: "100%",
              display: "block",
              marginLeft: "4em",
              paddingTop: "0.9em",
              lineHeight: "1",
              fontSize: "14pt",
            }}
          >
            PharMatch by
          </h1>
          <h1
            style={{
              fontWeight: "600",
              fontFamily: "Poppins",
              width: "100%",
              display: "block",
              marginLeft: "4em",
              paddingTop: "0.2em",
              lineHeight: "1",
              fontSize: "14pt",
            }}
          >
            PharmaNUS
          </h1>
        </div>

        <FBModal
          prompt={
            <Fab
              sx={{
                zIndex: 3,
                width: 40,
                height: 40,
                backgroundColor: "#003D7C",
                fontWeight: 600,
                color: "white", 
                position: "absolute",
                right: "70px",
                top: "16px",
              }}
            >
              <QuestionMarkIcon
                fontSize="large"
                style={{ transform: "scale(1.4)" }}
              />
            </Fab>
          }
          title={<h3 style={{ fontWeight: "600", fontSize: "14pt" }}>How to Play PharMatch</h3>}
          body={
            <div className="modalBody" style={{ textAlign: "center" }}>
              Use the menu in the top left corner (
              <MenuIcon
                fontSize="small"
                style={{ transform: "scale(1.3) translateY(2px)" }}
              />
              ) to select the range of systems or topics to study.
              <div className="line-spacing-0-5"><br /></div>
              "Foundational" includes autonomic nervous system pharma{"\u00AD"}cology, ophthalmics, 
              analgesics, anti-inflamm{"\u00AD"}atory drugs, immuno{"\u00AD"}suppress{"\u00AD"}ants, and anesthetics.
              <div className="line-spacing-0-5"><br /></div>
              "Cardiovascular" includes renal system drugs.
              <div className="line-spacing-0-5"><br /></div>
              "Musculoskeletal" includes rheumatological and dermatological drugs.
              <div className="line-spacing-0-5"><br /></div>
              "Neuroscience" includes neurological and mental health drugs.
              <div className="line-spacing-0-5"><br /></div>
              "Limited to SG NDF" selects for drug classes found in the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ndf.gov.sg/"
              >
              Singapore National Drug Formulary
              </a>
              {" "}while "WHO Stem Book" selects for all drug classes listed in the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/stembook-2018.pdf?sfvrsn=32a51b3c_6&download=true"
              >
                WHO INN Stem Book 2018
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/addendum-stembook2018-202302.pdf?sfvrsn=56329839_3&download=true"
              >
                2023 Addendum
              </a>.
              <div className="line-spacing-0-5"><br /></div>
              "Top Drugs Only" selects for commonly prescribed drugs while "Expanded Drug List" selects for all drugs found in the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/stembook-2018.pdf?sfvrsn=32a51b3c_6&download=true"
              >
                WHO INN Stem Book 2018
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/addendum-stembook2018-202302.pdf?sfvrsn=56329839_3&download=true"
              >
                2023 Addendum
              </a>.
            </div>
          }
        />
        <FBModal
          prompt={
            <Fab
              sx={{
                zIndex: 3,
                width: 40,
                height: 40,
                backgroundColor: "#003D7C",
                fontWeight: 600,
                color: "white", 
                position: "absolute",
                right: "20px",
                top: "16px",
              }}
            >
              <InfoIcon
                fontSize="large"
                style={{ transform: "scale(1.4)" }}
            />
            </Fab>
          }
          title={<h3 style={{ fontWeight: "600", fontSize: "14pt" }}>About PharMatch</h3>}
          body={ 
            <div className="modalBody" style={{ textAlign: "center" }}>
              This game is a study aid for medical and health professions students learning pharmacology.
              <br />
              <br />
              The goal is to match pairs, similar to the card game "concentration" or "memory",
              but instead of matching two identical words, you're matching WHO inter{"\u00AD"}national 
              non{"\u00AD"}proprietary name (INN) stems with their definitions from the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/stembook-2018.pdf?sfvrsn=32a51b3c_6&download=true"
              >
                WHO INN Stem Book 2018
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cdn.who.int/media/docs/default-source/international-nonproprietary-names-(inn)/addendum-stembook2018-202302.pdf?sfvrsn=56329839_3&download=true"
              >
                2023 Addendum
              </a>.
              <br />
              <div className="center-divider"></div>
              <p>
                This project was built using{" "}
                <a href="https://reactjs.org/" title="React">
                  React
                </a>. Code adapted by{" "}
                <a
                  href="https://github.com/H4mes"
                  title="H4mes"
                >
                  H4mes
                </a> and{" "}
                <a
                  href="https://blog.nus.edu.sg/phcdgs/resources-for-learners/"
                  title="PharmaNUS"
                >
                  PharmaNUS
                </a>{" "}
                from Github:{" "}
                <a
                  href="https://github.com/gordonmaloney/cuimhne"
                  title="gordonmaloney/cuimhne"
                >
                  @gordonmaloney/cuimhne
                </a>{" "}
                by Gordon Maloney.
              </p>
              <div className="center-divider"></div>
              <p style={{fontSize: "0.75rem"}}>
              DISCLAIMER: The information provided in this application is for educational purposes only and is not intended as medical advice. 
              The contents should not be used for diagnosing or treating a medical or health condition. Always consult with a qualified
              healthcare professional before making any medical decisions or changes to your healthcare regimen. The authors of this 
              application are not liable for any damages or losses arising from the use of this information.
              </p>
            </div>
          }
        />
        <Drawer
          style={{ zIndex: 2 }}
          anchor="left"
          open={show}
          onClose={() => setShow(false)}
          PaperProps={{
            sx: {
              backgroundColor: "#EF7C00",
            },
          }}
        >
          <Box sx={{ width: 250, padding: 2 }}>
            <br />
            <br />
            <h3 style={{ fontSize: "14pt", color: "#003D7C" }}>Filter Systems</h3>
            <p style={{ fontSize: "10pt", color: "#003D7C" }}>
              If you're studying a systems-based curriculum, you can filter which systems or topics
              you'd like to see drugs from using the slider below:
            </p>
            {/* Render LevelSelect here */}
            <LevelSelect 
              handleCloseDrawer={handleCloseDrawer}
              handleShowWarning={handleShowWarning}
            />
          </Box>
        </Drawer>
        <Modal
          open={showWarning}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={handleCloseWarning}
          closeAfterTransition
          BackdropComponent={StyledBackdrop}
          BackdropProps={{ timeout: 5000 }}
        >
          <Fade in={showWarning}>
            <Box sx={style}>
              <div
                style={{
                  zIndex: 0,
                  padding: "0",
                  margin: "0",
                  marginLeft: "-0.0125em",
                  marginTop: "-0.03125em",
                  paddingTop: "0.1875em",
                  width: "100%",
                  height: "2.8125em",
                  borderRadius: "6px 6px 0 0",
                  backgroundColor: "#003D7C",
                }}
              >
                <div
                  style={{
                    float: "right",
                    display: "block",
                    color: "#EF7C00",
                    paddingTop: "0.3125em",
                    paddingRight: "0.625em",
                  }}
                >
                  <CloseIcon onClick={handleCloseWarning} />
                </div>
                <h2
                  style={{
                    display: "block",
                    padding: "0",
                    margin: "0",
                    textAlign: "left",
                    paddingLeft: "0.625em",
                    paddingBottom: "0",
                    paddingTop: "0.35em",
                    fontSize: "1.125em",
                  }}
                >
                  Attention!
                </h2>
              </div>
              <center>
                <div style={{ paddingTop: "1.25em", paddingBottom: "1.875em" }}>
                  <h3
                    style={{
                      display: "flex",
                      padding: "0",
                      margin: "0",
                      textAlign: "center",
                      paddingLeft: "0.625em",
                      paddingBottom: "0",
                      paddingTop: "0.35em",
                      fontSize: "1.125em",
                    }}
                  >
                    Please select at least one topic before generating a deck.
                  </h3>
                </div>
              </center>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};
